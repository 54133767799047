const mobileMenuIcon = document.querySelector(".menu-mobile__icon") as HTMLElement
const mobileMenu = document.querySelector(".menu-mobile__overlay") as HTMLElement
const menuOverlay = document.querySelector(".menu-mobile__overlay") as HTMLElement
const html = document.querySelector("html") as HTMLElement

if(menuOverlay){
  menuOverlay.addEventListener("click", e => {
    if((e.target as Element).classList.contains("menu-mobile__overlay--active")){
      mobileMenu.classList.remove("menu-mobile__overlay--active")
      mobileMenuIcon.classList.remove("menu-mobile__icon--open")
      html.style.overflow="auto"
    }
  })
}

if(mobileMenuIcon){
  mobileMenuIcon.addEventListener("click", e => {
    if(mobileMenuIcon.classList.contains("menu-mobile__icon--open")){
      mobileMenuIcon.classList.remove("menu-mobile__icon--open")
      mobileMenu.classList.remove("menu-mobile__overlay--active")
      html.style.overflow="auto"
    }else {
      mobileMenuIcon.classList.add("menu-mobile__icon--open")
      mobileMenu.classList.add("menu-mobile__overlay--active")
      html.style.overflow="hidden"
    }
  })
}