{
  const productInquiryOverlay = document.querySelector(".product-inquiry__overlay") as HTMLDivElement;

  if (productInquiryOverlay) {
    const productInquiryCloseButton = productInquiryOverlay.querySelector(".product-inquiry__close") as HTMLButtonElement;

    productInquiryCloseButton.addEventListener("click", () => {
      productInquiryOverlay.classList.remove("product-inquiry__overlay--active");
    })
  }
}