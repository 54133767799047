const form = document.querySelector(".umbraco-forms-container") as HTMLElement
if(form)
{
    const shouldShareContactInfoDiv = form.querySelector('.shouldsharecontactinfo') as HTMLElement;
    if ( shouldShareContactInfoDiv) {
        document.addEventListener("DOMContentLoaded", () => {
            if (!shouldShareContactInfoDiv) return;

            const nameDiv = form.querySelector('.name') as HTMLElement;
            const phoneNumberDiv = form.querySelector('.phonenumber') as HTMLElement;
            const emailDiv = form.querySelector('.email') as HTMLElement;

            const yesRadio = shouldShareContactInfoDiv.querySelector('input[value="yes"]') as HTMLInputElement;
            const noRadio = shouldShareContactInfoDiv.querySelector('input[value="no"]') as HTMLInputElement;

            if (yesRadio && noRadio) {
                yesRadio.addEventListener("change", () => {
                    nameDiv.classList.remove("hidden");
                    phoneNumberDiv.classList.remove("hidden");
                    emailDiv.classList.remove("hidden");
                });

                noRadio.addEventListener("change", () => {
                    nameDiv.classList.add("hidden");
                    phoneNumberDiv.classList.add("hidden");
                    emailDiv.classList.add("hidden");
                });
            }
        });
    }
}