import { wrapGrid } from 'animate-css-grid'

const grid = document.querySelector(".grid") as HTMLElement

if(grid){
  wrapGrid(grid);
}

const teamMembers = document.querySelectorAll(".team__card")
const closeButton = document.querySelector(".team__close") as HTMLElement

if(teamMembers) {
  Array.from(teamMembers).forEach(item => {

    item.addEventListener("click", e => {
      const target = e.target as Element;

      if(target.classList.contains("team__close") || target.classList.contains("team__close-icon")){
        resetCards()
      } else {
      resetCards()
      item.classList.add("team__card--active")
      item.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
      }
    })

    item.addEventListener("keyup", (e:KeyboardEvent) => {

      if(e.key == "Enter"){
        const target = e.target as Element;

        if(target.classList.contains("team__close-icon")){
          resetCards()
        } else {
        resetCards()
        item.classList.add("team__card--active")
        item.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
        }
      }
    })
  })
}

if(closeButton) {
  closeButton.addEventListener("click", e => {
    resetCards()
  })
}

function resetCards () {
  Array.from(teamMembers).forEach(card => {
    card.classList.remove("team__card--active")
  })
}