{
    const form = document.querySelector(".umbraco-forms-page") as HTMLElement

    if (form) {
        document.addEventListener("DOMContentLoaded", () => {
            const submitButton = form.querySelector('input[type="submit"]');
            if (submitButton) {
                const isArabic = (document.querySelector("#site-lang") as HTMLInputElement).value == "ar";
                if (isArabic) {
                    (submitButton as HTMLInputElement).value = "إرسال";
                }
            }
        });
    }
}