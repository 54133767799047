const accordionTopItems = document.querySelectorAll(".accordion__item-top")
let lastElm: HTMLElement = undefined

if(accordionTopItems) {
  Array.from(accordionTopItems).forEach((item: HTMLElement) => {
    item.addEventListener("click", e => {
      const target = e.target as HTMLElement
      toggleSwitch(target)
    })

    item.addEventListener("keyup", (e: KeyboardEvent) => {
      if(e.key == "Enter") {
        toggleSwitch(item)
      }
    })

    const toggleSwitch = (elm: HTMLElement) => {
      elm.classList.toggle("accordion__item-top--active")
      elm.nextElementSibling.classList.toggle("accordion__item-inner--active")
      if (!lastElm) lastElm = elm;
      else {
        if (lastElm != elm) closeAccordion(lastElm);
        lastElm = elm;
      }
    }

    const closeAccordion = (elm: HTMLElement) => {
      elm.classList.remove("accordion__item-top--active")
      elm.nextElementSibling.classList.remove("accordion__item-inner--active")
    }
  })
}