const navbarLinks = document.querySelectorAll(".navbar__link");
const search = document.querySelector(".navbar__search") as HTMLElement;
const searchInput = document.querySelector(".navbar__search-input") as HTMLInputElement;
const searchIcon = document.querySelector(".navbar__search-icon") as HTMLInputElement;
const searchCross = document.querySelector(".navbar__search-cross") as HTMLInputElement;
const linksContainer = document.querySelector(".navbar__links") as HTMLInputElement;
const languages = document.querySelectorAll(".navbar__language");
const selectedLanguages = document.querySelectorAll(".navbar__language-selected");
const currentLanguages = document.querySelectorAll(".navbar__language-current");
const languageDropdowns = document.querySelectorAll(".navbar__language-dropdown");
const languageIcons = document.querySelectorAll(".navbar__language-icon");

selectedLanguages.forEach((selectedLang, index) => {
    selectedLang.innerText = currentLanguages[index].value;
});

languages.forEach((language, index) => {
  language.addEventListener("click", e => {
    if (languageDropdowns[index].classList.contains("navbar__language-dropdown--active")) {
      languageDropdowns[index].classList.remove("navbar__language-dropdown--active");
      languageIcons[index].classList.remove("navbar__language-icon--active");
    } else {
      languageDropdowns[index].classList.add("navbar__language-dropdown--active");
      languageIcons[index].classList.add("navbar__language-icon--active");
    }
  });
});

document.addEventListener("click", (event) => {
  const target = event.target;

  languages.forEach((language, index) => {
    const dropdown = languageDropdowns[index];
    const icon = languageIcons[index];

    if (!dropdown || !icon) {
      return;
    }

    // If the clicked target is outside the dropdown and is not the language element itself
    if (!dropdown.contains(target) && target !== dropdown && target !== language) {
      dropdown.classList.remove("navbar__language-dropdown--active");
      icon.classList.remove("navbar__language-icon--active");
    }
  });
});

if(searchInput){
  window.addEventListener("click", e =>{
    if((e.target as Element).classList.contains("navbar__search-input") || (e.target as Element).classList.contains("navbar__search-icon")) {
      searchInput.classList.add("navbar__search-input--active")
      linksContainer.classList.add("navbar__links--hidden")
      searchIcon.classList.add("navbar__search-icon--hidden")
      searchCross.classList.add("navbar__search-cross--active")
    } else {
      searchInput.classList.remove("navbar__search-input--active")
      linksContainer.classList.remove("navbar__links--hidden")
      searchIcon.classList.remove("navbar__search-icon--hidden")
      searchCross.classList.remove("navbar__search-cross--active")
    }
  })
}

if(searchInput){
  searchInput.addEventListener("focus", e =>{
    searchInput.classList.add("navbar__search-input--active")
    linksContainer.classList.add("navbar__links--hidden")
  })
}

if(searchInput){
  searchInput.addEventListener("blur", e =>{
    searchInput.classList.remove("navbar__search-input--active")
          linksContainer.classList.remove("navbar__links--hidden")
  })
}

if (navbarLinks) {
  Array.from(navbarLinks).forEach((link) => {
    link.addEventListener("click", () => {
      const url = link.getAttribute("data-url");
      const target = link.getAttribute("data-target");

      if (target === "_blank") {
        window.open(url, target);
      } else {
        window.location.href = url;
      }
    });

    link.addEventListener("keyup", (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        const url = link.getAttribute("data-url");
        const target = link.getAttribute("data-target");

        if (target === "_blank") {
          window.open(url, "_blank");
        } else {
          window.location.href = url;
        }
      }
    });
  });
}

// displays bottom of navbar when nav is off screen
document.addEventListener("DOMContentLoaded", function() {
const navbarContainer = document.querySelector(".navbar-wrapper");
if (navbarContainer === null) return;
const navBottom = document.getElementById('nav-bottom');

    var observerOptions = {
      root: null,
      rootMargin: '-10px',
      threshold: 0
  };

  var observer = new IntersectionObserver(function(entries, observer) {
      entries.forEach(entry => {
          if (!entry.isIntersecting) {
              navBottom.style.opacity = '1';
          } else {
              navBottom.style.opacity = '0';
          }
      });
  }, observerOptions);

  observer.observe(navbarContainer);
});

// function getCrumbs() {

//   let path = location.href.split('/').slice(3);
//   const breadcrumbsWrapper = document.querySelector('.navbar__breadcrumbs-wrapper') || null

//     if(path.length > 0 && breadcrumbsWrapper){

//       breadcrumbsWrapper.classList.add("navbar__breadcrumbs-wrapper--visible")

//       const linkPaths = [];
  
//       for (let i = 0; i < path.length; i++) {
//         const component = path[i];
      
//         let anchorText = decodeURIComponent(component).split('.')[0];
//         anchorText = anchorText.charAt(0).toUpperCase() + anchorText.slice(1);
      
//         const link = '/' + path.slice(0, i + 1).join('/');
      
//         linkPaths.push({ "main": anchorText, "link": link });
//       }
      
//       linkPaths.forEach(path =>{
//         const breadcrumbs = document.querySelector('.navbar__breadcrumbs')
      
//         if(breadcrumbs){
//           let a = document.createElement("a");
      
//           a.textContent = path.main
//           a.href=path.link
        
//           a.className="navbar__breadcrumb flex flex--align-center flex--justify-center"
          
//           breadcrumbs.appendChild(a)
//         }
//       })
//     }
// }

// getCrumbs()