const reportsTabInputs = document.querySelectorAll<HTMLInputElement>(".reports-component__radio");
const reportsTabLabels = document.querySelectorAll<HTMLInputElement>(".reports-component__label");
const reportsCardsGrid = document.querySelectorAll<HTMLInputElement>(".reports-component__cards");
const reportsHeaders = document.querySelectorAll<HTMLInputElement>(".reports-component__header");
const reportsCards = document.querySelectorAll<HTMLInputElement>(".reports-component__card");
const closeButtons = document.querySelectorAll<HTMLInputElement>(".reports-component__close");

let reportsActiveTab = 0

if (reportsTabInputs.length > 0) {
  reportsTabInputs.forEach((input) => {
    input.addEventListener("change", handleReportsInputChange);
  });

  reportsTabLabels.forEach((input) => {
    input.addEventListener("keydown", e => {
      if(e.key == "Enter"){
        handleReportsInputChange(e, "keydown")
      }
    });
  });

  const firstInput = reportsTabInputs[0];
  firstInput.checked = true;
}

function handleReportsInputChange(event: Event, type = "click") {

  if(type === "keydown"){
    const target = event.target as HTMLElement;

    if((target.previousElementSibling as HTMLInputElement)){
      const activeTabIndex = Array.from(reportsTabInputs).indexOf((target.previousElementSibling as HTMLInputElement));
      reportsActiveTab = activeTabIndex;

      reportsTabInputs[reportsActiveTab].checked = true

      reportsCardsGrid.forEach(item=>item.style.display="none")
      reportsCardsGrid[reportsActiveTab].style.display = "grid"

      reportsHeaders.forEach(item=>item.style.display="none")
      reportsHeaders[reportsActiveTab].style.display = "flex"
    }
  } else {
    const target = event.target as HTMLInputElement;

    if (target.checked) {
      const activeTabIndex = Array.from(reportsTabInputs).indexOf(target);
      reportsActiveTab = activeTabIndex;

      reportsCardsGrid.forEach(item=>item.style.display="none")
      reportsCardsGrid[reportsActiveTab].style.display = "grid"

      reportsHeaders.forEach(item=>item.style.display="none")
      reportsHeaders[reportsActiveTab].style.display = "flex"
    }
  }
}

if(reportsCardsGrid.length > 0){
  reportsCardsGrid[reportsActiveTab].style.display = "grid"

  Array.from(reportsCards).forEach(card => {
    card.addEventListener("click", e => {
      handleCardClick(card)
    })

    card.addEventListener("keyup", e => {
      if(e.key === "Enter"){
        handleCardClick(card)
      }
    })
  })

  Array.from(closeButtons).forEach(button => {
    button.addEventListener("click", e => {
      e.stopImmediatePropagation()
      e.stopPropagation()
      resetCards()
    })

    button.addEventListener("keyup", e => {
      if(e.key === "Enter"){
        e.stopImmediatePropagation()
        e.stopPropagation()
        resetCards()
      }
    })
  })

  window.addEventListener("keyup", e => {
    if(e.key === "Escape"){
      resetCards()
    }
  })
}

if(reportsHeaders.length > 0){
  reportsHeaders[reportsActiveTab].style.display = "flex"
}

function handleCardClick (item){
  resetCards()
  item.classList.add("reports-component__card--active")
  item.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
}

function resetCards () {
  Array.from(reportsCards).forEach(card => {
    card.classList.remove("reports-component__card--active")
  })
}