const dropdown = document.getElementById("location__select") as HTMLInputElement
const image = document.querySelector(".location__map") as HTMLElement
const address = document.querySelector(".location__address-text") as HTMLElement
const phone = document.querySelector(".location__phone-text") as HTMLElement
const times = document.querySelector(".location__times-text") as HTMLElement
const title = document.querySelector(".location__title") as HTMLElement
declare var google: any;

let marker;
let map;

if(dropdown){
  window.addEventListener("DOMContentLoaded", e => {
    if (!document.getElementById('map')) return;
    const data = JSON.parse(dropdown.value)
    updateData(data)
  })

  dropdown.addEventListener("change", e => {
    const data = JSON.parse(dropdown.value)
    updateData(data)
  })

  function updateData(data) {
    address.textContent=data.location
    phone.textContent=data.phoneNumber
    phone.setAttribute("href", `tel:${data.phoneNumber}`)
    times.textContent=data.openingTimes
    title.textContent=data.title
    if (marker && map) {
      let this_location = { lat: data.lat, lng: data.lng };
      marker.setPosition(this_location);
      map.panTo(this_location);
    }
  }

  (window as any).initMap = function() {
    let mapElement = document.getElementById('map');
    if (!mapElement) {
        return;
    }

    let lat = 24.774265, lng = 46.738586;
    if (dropdown) {
      const data = JSON.parse(dropdown.value)
      updateData(data)
      lat = data.lat
      lng = data.lng
    }
    let location = { lat, lng };
    map = new google.maps.Map(mapElement, {
        zoom: 14,
        center: location
    });

    marker = new google.maps.Marker({
        position: location,
        map: map
    });
  }
}