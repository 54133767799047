const tabInputs = document.querySelectorAll<HTMLInputElement>(".tabbed-component__radio");

if (tabInputs.length > 0) {
  const tabLabels = document.querySelectorAll<HTMLInputElement>(".tabbed-component__label");
  const cards = document.querySelectorAll<HTMLInputElement>(".tabbed-component__card");

  let activeTab = 0

  tabInputs.forEach((input) => {
    input.addEventListener("change", (e) => {
      handleTabbedComponentInputChange(e);
    });
  });

  tabLabels.forEach((input) => {
    input.addEventListener("keydown", e => {
      if(e.key == "Enter"){
        handleTabbedComponentInputChange(e, "keydown")
      }
    });
  });

  const firstInput = tabInputs[0];
  firstInput.checked = true;

  const handleTabbedComponentInputChange = (event: Event, type = "click") => {
    if(type === "keydown"){
      const target = event.target as HTMLElement;
  
      if((target.previousElementSibling as HTMLInputElement)){
        const activeTabIndex = Array.from(tabInputs).indexOf((target.previousElementSibling as HTMLInputElement));
        activeTab = activeTabIndex;
  
        tabInputs[activeTab].checked = true
  
        cards.forEach(item=>item.style.display="none")
        cards[activeTab].style.display = "flex"
      }
    } else {
      const target = event.target as HTMLInputElement;
  
      if (target.checked) {
        const activeTabIndex = Array.from(tabInputs).indexOf(target);
        activeTab = activeTabIndex;
  
        cards.forEach(item=>item.style.display="none")
        cards[activeTab].style.display = "flex"
      }
    }
  }
  
  if(cards.length > 0){
    cards[activeTab].style.display = "flex"
  }
}