const parallaxContainer = document.querySelector('.feature-cta') as HTMLElement;
const leftContainer = document.querySelector('.feature-cta__left') as HTMLElement;
const rightContainer = document.querySelector('.feature-cta__right') as HTMLElement;

if (parallaxContainer) {
    if (window.innerWidth > 992) {
        window.addEventListener('scroll', function () {
            const containerTop = parallaxContainer.offsetTop;
            const containerHeight = parallaxContainer.offsetHeight;
            const containerBottom = containerTop + containerHeight;

            const windowScrollTop = window.pageYOffset;
            const windowBottom = windowScrollTop + window.innerHeight;

            if (windowBottom > containerTop && windowScrollTop < containerBottom) {
                const scrollPercent = (windowScrollTop - containerTop) / containerHeight;
                leftContainer.style.transform = `translateY(${scrollPercent * 30}px)`;
                rightContainer.style.transform = `translateY(${-scrollPercent * 30}px)`;
            }
        });
    }
}
