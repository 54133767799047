{
    const form = document.querySelector(".umbraco-forms-page");
    const failureOverlay = document.querySelector(".feedback__overlay__failure")
    if (form && failureOverlay) {
        document.addEventListener("DOMContentLoaded", () => {
            failureOverlay.classList.remove("hidden")
            document.querySelector('.form-group')
            .insertAdjacentElement('beforebegin', document.querySelector('.feedback__overlay__failure'));

            const inputElement = document.querySelector('.form-group input') as HTMLInputElement | undefined;
            if (inputElement) inputElement.style.border = '1px solid #FF9898';
        })
    }
}