const lhsTabs = document.querySelectorAll<HTMLInputElement>(".lhs-tab__radio");

if (lhsTabs.length > 0) {
    const cards = document.querySelectorAll<HTMLInputElement>(".lhs-tab__card");
    let activeTab = 0;

    const updateDisplay = (index: number) => {
        cards.forEach(item => item.style.display = "none");
        cards[index].style.display = "flex";
    };

    const handleEvent = (event: Event, type = "click") => {
        const target = event.target as HTMLElement;
        const inputEl = type === "keydown" ? target.previousElementSibling : target;
        if (!(inputEl instanceof HTMLInputElement) || !inputEl.checked) return;

        activeTab = Array.from(lhsTabs).indexOf(inputEl);
        updateDisplay(activeTab);
    };

    lhsTabs[0].checked = true;
    updateDisplay(activeTab);

    if (lhsTabs.length > 1) {
        lhsTabs.forEach(input => input.addEventListener("change", handleEvent));
        document.querySelectorAll<HTMLInputElement>(".lhs-tab__label").forEach(label =>
            label.addEventListener("keydown", e => e.key === "Enter" && handleEvent(e, "keydown"))
        );
    } else {
        document.querySelector<HTMLElement>(".lhs-tab__tabs").style.display = "none";
    }
}