
document.addEventListener("DOMContentLoaded", () => {
    const arabicText = document.querySelector<HTMLInputElement>("input[id='arabicWidgetText']")?.value || "المساعدة"
    const englishText = document.querySelector<HTMLInputElement>("input[id='englishWidgetText']")?.value || "Support"
    const isArabic = document.querySelector("html[dir='rtl']") !== null;
    const text = isArabic ? arabicText : englishText;
    let firstRun = true;
    
    // @ts-ignore
    if (typeof zE === 'undefined') return;
    
    // @ts-ignore
    zE(function () {
        // @ts-ignore
        $zopim(function () {
            // @ts-expect-error
            $zopim.livechat.hideAll();

            // @ts-expect-error
            $zopim.livechat.setLanguage(isArabic ? 'ar' : 'en');

            // @ts-expect-error
            $zopim.livechat.badge.setColor('#0070DF');

            // @ts-expect-error
            $zopim.livechat.theme.reload();
        });
    });

    const styleWidget = (button: HTMLButtonElement) => {
        const icon = button.querySelector<HTMLSpanElement>("span[data-testid='Icon--chat']");
        const label = button.querySelector<HTMLSpanElement>("span[data-testid='launcher-label']");

        icon?.remove();
        label?.remove();

        // Only add the image when it has not been added before
        if (!button.querySelector("img")) {
            let imageElm = document.createElement("img");
            imageElm.src = "/assets/img/img/messages-icon.svg";
            imageElm.style.width = "44px";
            imageElm.style.height = "44px";

            button.insertBefore(imageElm, label);
        }

        button.setAttribute("aria-label", text);
        button.setAttribute("style", "display: flex !important;");
        button.style.justifyContent = "center";
        button.style.alignItems = "center";
        button.style.gap = "15px";
        button.style.padding = "8px 8px 8px 8px";
        button.style.transition = "transform 0.3s ease-in-out";
        button.addEventListener("mouseover", () => {
            button.style.transform = "scale(0.9)";
        });
        button.addEventListener("mouseout", () => {
            button.style.transform = "scale(1)";
        });
    }

    const onIframeFound = (iframe: HTMLIFrameElement) => {
        const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
        const embed = innerDoc.querySelector<HTMLDivElement>("#Embed");

        if (embed) {
            const floatRightDiv = embed.querySelector<HTMLDivElement>("div[style='float: right;']");
            if (floatRightDiv) {
                floatRightDiv.style.cssFloat = "left";
            }
            
            const button = embed.querySelector<HTMLButtonElement>("button[data-testid='launcher']");
            if (button) {
                styleWidget(button);
            }

            if (firstRun) {
                firstRun = false;
                // @ts-expect-error
                setTimeout(() => $zopim.livechat.badge.show(), 1000);
            }
        }
    };

    const widgetCallback = (mutationsList: MutationRecord[]) => {
        mutationsList.forEach(() => {
            const iframe = document.querySelector<HTMLIFrameElement>("#launcher");

            if (iframe) {
                onIframeFound(iframe);
            }
        })
    };

    const widgetObservor = new MutationObserver(widgetCallback);
    const widgetConfig = { childList: true, subtree: true, attributes: true, characterData: true };

    widgetObservor.observe(document, widgetConfig);
})