import Glide from '@glidejs/glide'

// stops carousel breaking in rtl
const isRTL = document.documentElement.getAttribute('dir') === 'rtl';
const featureItems = document.querySelectorAll('.feature-hero__item, .feature-hero__item-full');
const lastIndex = featureItems.length - 1;

if (isRTL) {
    const featureItemsList = document.querySelector('.feature-hero__slides');
    if(featureItemsList != null)
    {
      featureItemsList.setAttribute('dir', 'rtl');

      featureItems.forEach((item: Element) => {
          item.setAttribute('dir', 'rtl');
      });
  }
}

// carousel controls
var autoplay_input = document.querySelector('#options-autoplay-input');
var autoplay = 0;
if(autoplay_input != undefined)
{ 
  autoplay = autoplay_input.value;
}

if(document.querySelector(".feature-hero__inner")){

  if(autoplay > 0)
  {    
    var glide = new Glide('.feature-hero__inner', {
      perView: 1,
      gap: 0,
      startAt: isRTL ? lastIndex : 0,
      autoplay: autoplay
    })
    
    glide.mount()

    glide.on('move.after', () =>{
      const carouselElement = document.querySelector('.feature-hero__inner') as HTMLElement;
      if (carouselElement) {
        carouselElement.style.opacity = '1';
        carouselElement.style.visibility = 'visible';
      }

      const bullets = document.querySelectorAll(".feature-hero__bullet")
      const currentIndex = glide.index;

      Array.from(bullets).forEach((bullet, index) => {
        bullet.classList.remove("glide__bullet--active")

        bullet.addEventListener("click", e =>{
          glide.go(`=${index}`)
        })
      })

      Array.from(bullets)[currentIndex].classList.add("glide__bullet--active")
    })
  }
  else
  {
    var glide = new Glide('.feature-hero__inner', {
      perView: 1,
      startAt: isRTL ? lastIndex : 0,
      gap: 0
    })
    
    glide.mount()

    glide.on('move.after', () =>{
      const bullets = document.querySelectorAll(".feature-hero__bullet")
      const currentIndex = glide.index;

      Array.from(bullets).forEach((bullet, index) => {
        bullet.classList.remove("glide__bullet--active")

        bullet.addEventListener("click", e =>{
          glide.go(`=${index}`)
        })
      })

      Array.from(bullets)[currentIndex].classList.add("glide__bullet--active")
    })
  }
}