const closeButton = document.querySelector(".news-detail__close") as HTMLElement

if(closeButton) {
    closeButton.addEventListener("click", e => {
      const currentURL = window.location.href;
      const urlObject = new URL(currentURL);
      const pathSegments = urlObject.pathname.split('/');

      if (pathSegments.length > 1) {
        pathSegments.pop();
      }

      const modifiedPath = pathSegments.join('/');

      urlObject.pathname = modifiedPath;
      const modifiedURL = urlObject.href;

      window.location.href = modifiedURL
    })
}