const accordionItems = document.querySelectorAll(".footer-accordion__item-top")

Array.from(accordionItems).forEach(item =>{
  item.addEventListener("click", (e) => {
    triggerAccordion(e)
  })
})

function triggerAccordion(e) {
  if(e.target.parentElement.nextElementSibling.classList.contains("footer-accordion__item-inner--active")){
    e.target.parentElement.classList.remove("footer-accordion__item-top--active")
    e.target.parentElement.nextElementSibling.classList.remove("footer-accordion__item-inner--active")
    e.target.classList.remove("footer-accordion__item-title--active")

  }else {
    e.target.parentElement.classList.add("footer-accordion__item-top--active")
    e.target.parentElement.nextElementSibling.classList.add("footer-accordion__item-inner--active")
    e.target.classList.add("footer-accordion__item-title--active")
  }
}