const targetImage = document.querySelector('.download-cta__image') as HTMLElement;
let threshold = 0.85


// mobile animation threshold
if (window.innerWidth < 992) {
    threshold = 0.5
}

if (targetImage) {
    document.addEventListener('DOMContentLoaded', function() {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio >= 0.5) {
                    entry.target.classList.add('download-cta__image--visible');
                }
            });
        }, {
            threshold: threshold
        });
    
        observer.observe(targetImage);
    });
}
