const tabCalculatorInputs = document.querySelectorAll<HTMLInputElement>(".tabbed-calculators__radio");
const tabCalculatorLabels = document.querySelectorAll<HTMLInputElement>(".tabbed-calculators__label");
const calculatorCards = document.querySelectorAll<HTMLInputElement>(".tabbed-calculators__cards");

let calculatorActiveTab = 0

if (tabCalculatorInputs.length > 0) {
  tabCalculatorInputs.forEach((input) => {
    input.addEventListener("change", handleTabbedCalculatorsInputChange);
  });

  tabCalculatorLabels.forEach((input) => {
    input.addEventListener("keydown", e => {
      if(e.key == "Enter"){
        handleTabbedCalculatorsInputChange(e, "keydown")
      }
    });
  });

  const firstInput = tabCalculatorInputs[0];
  firstInput.checked = true;
}

function handleTabbedCalculatorsInputChange(event: Event, type = "click") {

  if(type === "keydown"){
    const target = event.target as HTMLElement;

    if((target.previousElementSibling as HTMLInputElement)){
      const activeTabIndex = Array.from(tabCalculatorInputs).indexOf((target.previousElementSibling as HTMLInputElement));
      calculatorActiveTab = activeTabIndex;

      tabCalculatorInputs[calculatorActiveTab].checked = true

      calculatorCards.forEach(item=>item.style.display="none")
      calculatorCards[calculatorActiveTab].style.display = "block"
    }
  } else {
    const target = event.target as HTMLInputElement;

    if (target.checked) {
      const activeTabIndex = Array.from(tabCalculatorInputs).indexOf(target);
      calculatorActiveTab = activeTabIndex;

      calculatorCards.forEach(item=>item.style.display="none")
      calculatorCards[calculatorActiveTab].style.display = "block"
    }
  }
}

if(calculatorCards.length > 0){
  calculatorCards[calculatorActiveTab].style.display = "block"
}