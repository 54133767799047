import { CalculatorOutput } from "./personalCalculator.types";

function generatePaymentScheduleWithFees(principal: number, tenureMonths: number, annualFlatRate: number, managementFee: number, startDate: Date) {
    let monthlyInterestRate = annualFlatRate / 12;
    let totalInterest = principal * monthlyInterestRate * tenureMonths;
    let totalRepayment = principal + totalInterest;
    let monthlyPayment = totalRepayment / tenureMonths;

    // Initial outflow: negative principal amount plus management fees
    let initialOutflow = -principal + managementFee;

    let paymentSchedule = [{
        paymentNumber: 0, // Initial outflow
        paymentDate: startDate.toISOString().split('T')[0], // Format YYYY-MM-DD
        paymentAmount: initialOutflow.toFixed(2)
    }];

    let paymentDate = new Date(startDate);
    // Adjust the payment date for the second payment based on the new rule
    if (startDate.getDate() <= 14) {
        // If before the 14th, set the first payment for the 27th of the same month
        paymentDate.setDate(27);
    } else {
        // If on the 14th or later, set the first payment for the 27th of the next month
        paymentDate.setMonth(paymentDate.getMonth() + 1);
        paymentDate.setDate(27);
    }

    for (let i = 1; i <= tenureMonths; i++) {
        paymentSchedule.push({
            paymentNumber: i,
            paymentDate: paymentDate.toISOString().split('T')[0], // Format YYYY-MM-DD
            paymentAmount: monthlyPayment.toFixed(2)
        });

        // Increment month for the next payment
        paymentDate.setMonth(paymentDate.getMonth() + 1);
    }

    return paymentSchedule;
}

function xirr(cashFlows: number[], dates: Date[]) {
    const maxIterations = 100;
    const precision = 0.0000001;
    let guess = 0.1;

    function dateDiffInDays(date1, date2) {
        const MS_PER_DAY = 24 * 60 * 60 * 1000;
        return Math.round((date2 - date1) / MS_PER_DAY);
    }

    function npv(rate) {
        return cashFlows.reduce((acc, val, i) => {
            const days = dateDiffInDays(dates[0], dates[i]);
            return acc + (val / Math.pow(1 + rate, days / 365));
        }, 0);
    }

    for (let i = 0; i < maxIterations; i++) {
        const npv1 = npv(guess);
        const npv2 = npv(guess + precision);

        const derivative = (npv2 - npv1) / precision;
        const nextGuess = guess - npv1 / derivative;

        if (Math.abs(nextGuess - guess) < precision) {
            return nextGuess;
        }

        guess = nextGuess;
    }

    //XIRR did not converge
    return 0;
}

function calculateLoanDetails(principal: number, tenureMonths: number, annualFlatRate: number, managementFee: number, startDate: Date): CalculatorOutput {
    const paymentSchedule = generatePaymentScheduleWithFees(principal, tenureMonths, annualFlatRate, managementFee, startDate);

    const cashFlows = paymentSchedule.map(payment => parseFloat(payment.paymentAmount));
    const dates = paymentSchedule.map(payment => new Date(payment.paymentDate));
    const g14 = ((principal * annualFlatRate * tenureMonths / 12) + principal) / tenureMonths;

    return {
        apr: xirr(cashFlows, dates) * 100,
        monthlyPayment: g14,
    };
}

function calculatePrincipalAmount(monthlyIncome: number, tenureMonths: number, annualFlatRate: number, maxThreshold: number = 1600000) {
    const g8 = annualFlatRate;
    let g6 = 0.45;
    if (monthlyIncome >= 25000) {
        g6 = 0.75;
    }

    let g7 = tenureMonths;
    let g9 = monthlyIncome * g6;
    let g12 = g9 * g7;
    let g13 = Math.min(maxThreshold, g12 / (1 + (g7 * g8 / 12)));

    return g13;
}

export { calculateLoanDetails, calculatePrincipalAmount };